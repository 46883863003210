import Vue from 'vue'
import { BootstrapVue, IconsPlugin  } from 'bootstrap-vue'
import VeeValidate, { Validator  } from 'vee-validate'
import vSelect from 'vue-select'
import VueSSE from 'vue-sse'
import store from './store'
import router from './router'
import App from './App.vue'

import FactoryRepository from '@/api/FactoryRepository'
import helperFunctions from '@/libs/helper'
import '@/libs/toastification'
import JWT from '@/auth/JWT'

// filters
import '@/filters/filters'

import 'vue-select/dist/vue-select.css'


// SSE
Vue.use(VueSSE)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Vue VeeValidate
Vue.use(VeeValidate)
// v-select validation
const selectedValue = value =>  ['', null, undefined].indexOf(value.id) === -1
Validator.extend('selectedValue', {
  validate: selectedValue,
  getMessage: field => `The ${  field  } field is required`
})

// Vue select use
Vue.component('v-select', vSelect)

// Helper module
const helper = {
  install() {
    Vue.helper = helperFunctions
    Vue.prototype.$helper = helperFunctions
  },
}
Vue.use(helper)

// JWT
const Jwt = {
  install() {
    Vue.JWT = JWT
    Vue.prototype.$JWT = JWT
  },
}
Vue.use(Jwt)

// API Factory
FactoryRepository.init(JWT)
const api = {
  install() {
    Vue.AdjustInventories = FactoryRepository.get('AdjustInventories')
    Vue.prototype.$AdjustInventories = FactoryRepository.get('AdjustInventories')

    Vue.AdjustOrdering = FactoryRepository.get('AdjustOrdering')
    Vue.prototype.$AdjustOrdering = FactoryRepository.get('AdjustOrdering')

    Vue.Articles = FactoryRepository.get('Articles')
    Vue.prototype.$Articles = FactoryRepository.get('Articles')

    Vue.Banners = FactoryRepository.get('Banners')
    Vue.prototype.$Banners = FactoryRepository.get('Banners')

    Vue.Cars = FactoryRepository.get('Cars')
    Vue.prototype.$Cars = FactoryRepository.get('Cars')

    Vue.CashRegistries = FactoryRepository.get('CashRegistries')
    Vue.prototype.$CashRegistries = FactoryRepository.get('CashRegistries')

    Vue.Categories = FactoryRepository.get('Categories')
    Vue.prototype.$Categories = FactoryRepository.get('Categories')

    Vue.Enums = FactoryRepository.get('Enums')
    Vue.prototype.$Enums = FactoryRepository.get('Enums')

    Vue.Files = FactoryRepository.get('Files')
    Vue.prototype.$Files = FactoryRepository.get('Files')

    Vue.Inventories = FactoryRepository.get('Inventories')
    Vue.prototype.$Inventories = FactoryRepository.get('Inventories')

    Vue.Warehouses = FactoryRepository.get('Warehouses')
    Vue.prototype.$Warehouses = FactoryRepository.get('Warehouses')

    Vue.Mercure = FactoryRepository.get('Mercure')
    Vue.prototype.$Mercure = FactoryRepository.get('Mercure')

    Vue.Navigations = FactoryRepository.get('Navigations')
    Vue.prototype.$Navigations = FactoryRepository.get('Navigations')

    Vue.OptimalCarInventories = FactoryRepository.get('OptimalCarInventories')
    Vue.prototype.$OptimalCarInventories = FactoryRepository.get('OptimalCarInventories')

    Vue.Orders = FactoryRepository.get('Orders')
    Vue.prototype.$Orders = FactoryRepository.get('Orders')

    Vue.Products = FactoryRepository.get('Products')
    Vue.prototype.$Products = FactoryRepository.get('Products')

    Vue.ProductVariants = FactoryRepository.get('ProductVariants')
    Vue.prototype.$ProductVariants = FactoryRepository.get('ProductVariants')

    Vue.Streets = FactoryRepository.get('Streets')
    Vue.prototype.$Streets = FactoryRepository.get('Streets')

    Vue.Users = FactoryRepository.get('Users')
    Vue.prototype.$Users = FactoryRepository.get('Users')

    Vue.WebPages = FactoryRepository.get('WebPages')
    Vue.prototype.$WebPages = FactoryRepository.get('WebPages')

    Vue.FirebaseMessage = FactoryRepository.get('FirebaseMessage')
    Vue.prototype.$FirebaseMessage = FactoryRepository.get('FirebaseMessage')
  },
}
Vue.use(api)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
